export const xiachanDetail = [
    {
        id: 1,
        defalut: require('@/assets/img/xiachan/kh1.png'),
        active: require('@/assets/img/xiachan/kh2.png'),
        series: '夏禅系列',
        seriesName: '夏禅口红',
        intro: '水光感果冻质地 打造高亮玻璃唇',
        zhName: '酵色「夏禅口红」',
        enName: 'The Neverending Summer Matte Lip Rouge',
        price: '89',
        of: '3g',
        imgUrl: [
            { index: 1, url: require('@/assets/img/详情/夏蝉系列/夏蝉口红/小图/4.png'), bigUrl: require('@/assets/img/详情/夏蝉系列/夏蝉口红/大图/4.png') },
            { index: 2, url: require('@/assets/img/详情/夏蝉系列/夏蝉口红/小图/2.png'), bigUrl: require('@/assets/img/详情/夏蝉系列/夏蝉口红/大图/2.png') },
            { index: 3, url: require('@/assets/img/详情/夏蝉系列/夏蝉口红/小图/3.png'), bigUrl: require('@/assets/img/详情/夏蝉系列/夏蝉口红/大图/3.png') },
            { index: 3, url: require('@/assets/img/详情/夏蝉系列/夏蝉口红/小图/1.png'), bigUrl: require('@/assets/img/详情/夏蝉系列/夏蝉口红/大图/1.png') },
        ],
        color: [
            {
                active: '#c32338',
                name: '#501 夏舞绯火',
                span1: '草莓正红 显白公主色',
                span2: ' ',
                color: require('@/assets/img/详情/夏蝉系列/夏蝉口红/色号大图/01.png')
            },
            {
                active: '#e1767f',
                name: '#502 耳语甜茶',
                span1: '肉桂奶桃色 粉嘟嘟奶呼呼',
                span2: ' ',
                color: require('@/assets/img/详情/夏蝉系列/夏蝉口红/色号大图/02.png')
            },
            {
                active: '#eb8d58',
                name: '#503 杏荫凉树',
                span1: '清透杏子黄 日杂感的小心机',
                span2: '叠涂拯救闲置"荧光色"',
                color: require('@/assets/img/详情/夏蝉系列/夏蝉口红/色号大图/03.png')
            },

            {
                active: '#d5432f',
                name: '#504 橘梦黄昏',
                span1: '盛夏感橘子色 另类的显白',
                span2: ' ',
                color: require('@/assets/img/详情/夏蝉系列/夏蝉口红/色号大图/04.png')

            },
            {
                active: '#b94b21',
                name: '#505 浮木岛屿',
                span1: '焦糖棕黄色 打造复古夏日',
                span2: ' ',
                color: require('@/assets/img/详情/夏蝉系列/夏蝉口红/色号大图/05.png')
            },
            {
                active: '#cb6558',
                name: '#506 开到荼蘼',
                span1: '烟烤奶茶色 诠释高级感',
                span2: ' ',
                color: require('@/assets/img/详情/夏蝉系列/夏蝉口红/色号大图/06.png')
            },
            {
                active: '#d6775f',
                name: '#507 桂皮冷杏',
                span1: '土调杏橘色 可盐可甜',
                span2: ' ',
                color: require('@/assets/img/详情/夏蝉系列/夏蝉口红/色号大图/07.png')
            },
            {
                active: '#e65151',
                name: '#508 反甜石榴',
                span1: '石榴草莓红 清透温柔',
                span2: ' ',
                color: require('@/assets/img/详情/夏蝉系列/夏蝉口红/色号大图/08.png')
            },
            {
                active: '#c85663',
                name: '#509 酸梅软语',
                span1: '浓郁紫梅子色 显白不挑皮',
                span2: ' ',
                color: require('@/assets/img/详情/夏蝉系列/夏蝉口红/色号大图/09.png')
            },
        ]
    },
    {
        id: 2,
        defalut: require('@/assets/img/xiachan/sh1.png'),
        active: require('@/assets/img/xiachan/sh2.png'),
        series: '夏蝉系列',
        seriesName: '夏禅腮红',
        intro: '全新啫喱质地 打造日杂氛围感',
        zhName: '酵色「夏禅腮红」',
        enName: 'The Neverending Summer Blush',
        price: '69',
        of: '4.8g',
        imgUrl: [
            { index: 1, url: require('@/assets/img/详情/夏蝉系列/夏禅腮红/小图/4.png'), bigUrl: require('@/assets/img/详情/夏蝉系列/夏禅腮红/大图/4.png') },
            { index: 2, url: require('@/assets/img/详情/夏蝉系列/夏禅腮红/小图/2.png'), bigUrl: require('@/assets/img/详情/夏蝉系列/夏禅腮红/大图/2.png') },
            { index: 3, url: require('@/assets/img/详情/夏蝉系列/夏禅腮红/小图/3.png'), bigUrl: require('@/assets/img/详情/夏蝉系列/夏禅腮红/大图/3.png') },
            { index: 3, url: require('@/assets/img/详情/夏蝉系列/夏禅腮红/小图/1.png'), bigUrl: require('@/assets/img/详情/夏蝉系列/夏禅腮红/大图/1.png') },
        ],
        color: [
            {
                active: '#e49065',
                name: '#B04 海街日暮',
                span1: '奶油杏子色 丝慕可口',
                span2: ' ',
                color: require('@/assets/img/详情/夏蝉系列/夏禅腮红/色号大图/01.png')
            },
            {
                active: '#f4a5a7',
                name: '#N06 一刹晚风',
                span1: '灰调橘粉',
                span2: ' ',
                color: require('@/assets/img/详情/夏蝉系列/夏禅腮红/色号大图/02.png')
            },
            {
                active: '#f9ccc3',
                name: '#B05 酸橙气泡',
                span1: '夏日元气酸橙色 高级日杂感',
                span2: ' ',
                color: require('@/assets/img/详情/夏蝉系列/夏禅腮红/色号大图/03.png')
            },
            {
                active: '#f48e76',
                name: '#N07 粉漾余波',
                span1: '烟灰豆沙香槟 青春感伪素颜',
                span2: ' ',
                color: require('@/assets/img/详情/夏蝉系列/夏禅腮红/色号大图/04.png')
            },
        ]
    },
    {
        id: 3,
        defalut: require('@/assets/img/xiachan/yx1.png'),
        active: require('@/assets/img/xiachan/yx2.png'),
        series: '夏蝉系列',
        seriesName: '夏禅眼线液笔',
        zhName: '酵色「夏禅眼线液笔」',
        enName: 'The Neverending Summer Liquid Eyeliner',
        price: '59',
        of: '0.5ml',
        intro: '极细笔尖 速干成膜',
        imgUrl: [
            { index: 1, url: require('@/assets/img/详情/夏蝉系列/夏禅眼线笔/小图/4.png'), bigUrl: require('@/assets/img/详情/夏蝉系列/夏禅眼线笔/大图/4.png') },
            { index: 2, url: require('@/assets/img/详情/夏蝉系列/夏禅眼线笔/小图/2.png'), bigUrl: require('@/assets/img/详情/夏蝉系列/夏禅眼线笔/大图/2.png') },
            { index: 3, url: require('@/assets/img/详情/夏蝉系列/夏禅眼线笔/小图/3.jpg'), bigUrl: require('@/assets/img/详情/夏蝉系列/夏禅眼线笔/大图/3.jpg') },
            { index: 3, url: require('@/assets/img/详情/夏蝉系列/夏禅眼线笔/小图/1.png'), bigUrl: require('@/assets/img/详情/夏蝉系列/夏禅眼线笔/大图/1.png') },
        ],
        color: [
            {
                active: '#ef9353',
                name: '#09 酸橙',
                span1: '打造元气日杂氛围眼妆',
                span2: ' ',
                color: require('@/assets/img/详情/夏蝉系列/夏禅眼线笔/色号大图/1.jpg')
            }
        ]
    },
]

