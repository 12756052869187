export const hupoDetail = [
	{
		id: 1,
		defalut: require('@/assets/img/琥珀系列素材/产品模特图/HPCY1.jpg'),
		active: require('@/assets/img/琥珀系列素材/产品模特图/HPCY2.jpg'),
		series: '琥珀系列',
		seriesName: '琥珀唇釉',
		intro: '浓郁色泽 棉柔乳霜质感',
		zhName: '酵色「琥珀唇釉」',
		enName: 'Tortoise Shell Fluffy Matte Lipgloss',
		price: '79',
		of: '3.3g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/琥珀素材/唇釉/小图/4.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/唇釉/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/琥珀素材/唇釉/小图/2.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/唇釉/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/唇釉/小图/3.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/唇釉/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/唇釉/小图/1.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/唇釉/大图/1.jpg') },
		],
		color: [
			{
				active: '#a9424b',
				name: '#V01 粉褐白桃',
				span1: '温柔豆沙色 明媚动人',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/01.jpg')
			},
			{
				active: '#8e2828',
				name: '#V02 深海珊瑚 ',
				span1: '冷调棕红 秋冬必备',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/02.jpg')
			},
			{
				active: '#7f161c',
				name: '#V03 酒渍血珀',
				span1: '明艳苹果红 元气女团色',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/03.jpg')
			},
			{
				active: '#902c41',
				name: '#V04 微醺芋泥',
				span1: '烟熏梅子色 高冷气质',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/04.jpg')
			},
			{
				active: '#84443c',
				name: '#V05 杏仁肉桂',
				span1: '杏仁肉桂色 纯欲氛围',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/05.jpg')

			},
			{
				active: '#932017',
				name: '#V06 枫糖番茄',
				span1: '棕调烂番茄 复古显白',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/06.jpg')
			},
			{
				active: '#78241f',
				name: '#V07 琥珀糖浆',
				span1: '质感脏橘 迷人风情',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/07.jpg')
			},
			{
				active: '#822529',
				name: '#V08 糖霜枣泥',
				span1: '浓郁泥枣红 秋冬必备',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/08.jpg')
			},
			{
				active: '#b82f3c',
				name: '#V09 甜心辣妹',
				span1: '玫调火龙果色 亮眼辣妹',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/09.jpg')
			},
			{
				active: '#7f212d',
				name: '#V10 蒸汽梅果',
				span1: '紫调梅果色 气场加倍',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/10.jpg')
			},
			{
				active: '#a42316',
				name: '#V11 坠落花火',
				span1: '暖调红梨色 南法热情',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/11.jpg')
			},
			{
				active: '#b0523e',
				name: '#V12 迷雾陶土',
				span1: '奶杏土橘色 温柔纯欲',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/12.jpg')
			},
			{
				active: '#ac3d3a',
				name: '#V13 半醉桃气',
				span1: '玫瑰蜜桃调 软糯少女',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/唇釉/大图/13.jpg')
			},
		]
	},
	{
		id: 2,
		defalut: require('@/assets/img/琥珀系列素材/产品模特图/HPSH1.jpg'),
		active: require('@/assets/img/琥珀系列素材/产品模特图/HPSH2.jpg'),
		series: '颊彩',
		seriesName: '琥珀腮红',
		intro: '微醺色泽 日杂氛围感',
		zhName: '酵色「琥珀腮红」',
		enName: 'Tortoise Shell Blush',
		price: '79',
		of: '3.5g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/琥珀素材/腮红/小图/4.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/腮红/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/琥珀素材/腮红/小图/2.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/腮红/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/腮红/小图/3.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/腮红/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/腮红/小图/1.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/腮红/大图/1.jpg') },
		],
		color: [
			{
				active: '#e3bfb9',
				name: '#N01 玫瑰烤奶',
				span1: '肉粉玫瑰色 温柔气质',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/腮红/大图/n01.jpg')
			},
			{
				active: '#bc7c6a',
				name: '#N02 山茶拿铁',
				span1: '棕调土咖色 日系加持',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/腮红/大图/n02.jpg')
			},
			{
				active: '#c28a74',
				name: '#B01 焦糖银杏',
				span1: '焦糖奶橘色 慵懒氛围',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/腮红/大图/b01.jpg')
			},
			{
				active: '#c58073',
				name: '#B02 树莓朗姆',
				span1: '微醺豆沙色 黄皮挚爱',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/腮红/大图/b02.jpg')
			},
		]
	},
	{
		id: 3,
		defalut: require('@/assets/img/琥珀系列素材/产品模特图/HPYP1.jpg'),
		active: require('@/assets/img/琥珀系列素材/产品模特图/HPYP2.jpg'),
		series: '琥珀系列',
		seriesName: '琥珀综合盘',
		zhName: '酵色「琥珀综合盘」',
		enName: 'Tortoise Shell Eyeshadow Palette',
		price: '149',
		of: '9g',
		intro: '复古摩登 透明感纯欲妆',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/琥珀素材/琥珀眼盘/1.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/琥珀眼盘/01.jpg') },
			{ index: 2, url: require('@/assets/img/详情/琥珀素材/琥珀眼盘/2.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/琥珀眼盘/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/琥珀眼盘/3.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/琥珀眼盘/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/琥珀眼盘/4.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/琥珀眼盘/04.jpg') },
		],
		color: [
			{
				active: '#db893b',
				name: '#04 黄棕琥珀',
				span1: '橘棕色彩 日常实用',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/琥珀眼盘/004.jpg')
			},
			{
				active: '#e5b3a7',
				name: '#05 灰粉琥珀',
				span1: '灰粉裸妆 温柔细腻',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/琥珀眼盘/005.jpg')
			}
		]
	},
]

