export const fuguDetail = [
	{
		id: 1,
		defalut: require('@/assets/img/fugu/kh1.png'),
		active: require('@/assets/img/fugu/kh2.png'),
		series: '复古系列',
		seriesName: '复古哑光口红',
		intro: '色彩饱满 高级浓郁',
		zhName: '酵色「复古哑光口红」',
		enName: 'Vintage Rewind Matte Lip Rouge',
		price: '99',
		of: '4.3g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/复古系列/复古口红/小图/4.png'), bigUrl: require('@/assets/img/详情/复古系列/复古口红/大图/4.png') },
			{ index: 2, url: require('@/assets/img/详情/复古系列/复古口红/小图/2.png'), bigUrl: require('@/assets/img/详情/复古系列/复古口红/大图/2.png') },
			{ index: 3, url: require('@/assets/img/详情/复古系列/复古口红/小图/3.png'), bigUrl: require('@/assets/img/详情/复古系列/复古口红/大图/3.png') },
			{ index: 3, url: require('@/assets/img/详情/复古系列/复古口红/小图/1.png'), bigUrl: require('@/assets/img/详情/复古系列/复古口红/大图/1.png') },
		],
		color: [
			{
				active: '#9a5043',
				name: '#201 赤豆琉璃',
				span1: '烟熏赤豆梅子',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古口红/试色大图/01.png')
			},
			{
				active: '#70342f',
				name: '#202 炭烤沉木',
				span1: '红调榛果可可酱',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古口红/试色大图/02.png')
			},
			{
				active: '#722b25',
				name: '#203 沉落红泥',
				span1: '半巧枣红 暮绯浓情',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古口红/试色大图/03.png')
			},
			{
				active: '#7a2c2c',
				name: '#204 红幕暗玫',
				span1: '暗夜血红 黑化的公主',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古口红/试色大图/04.png')
			},
			{
				active: '#9b4b48',
				name: '#205 半醉红夜',
				span1: '复古皇后暗红',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古口红/试色大图/05.png')
			},
			{
				active: '#8d414b',
				name: '#206 霓虹酒馆',
				span1: '浆果血浆色 古堡伯爵红',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古口红/试色大图/06.png')
			},
		]
	},
	{
		id: 2,
		defalut: require('@/assets/img/fugu/cy1.png'),
		active: require('@/assets/img/fugu/cy2.png'),
		series: '复古系列',
		seriesName: '复古镜面口红',
		intro: '水感轻薄 持色玻璃唇',
		zhName: '酵色「复古镜面口红」',
		enName: 'Vintage Rewind Matte Lipgloss',
		price: '79',
		of: '3.2g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/复古系列/复古唇釉/小图/4.png'), bigUrl: require('@/assets/img/详情/复古系列/复古唇釉/大图/4.png') },
			{ index: 2, url: require('@/assets/img/详情/复古系列/复古唇釉/小图/2.png'), bigUrl: require('@/assets/img/详情/复古系列/复古唇釉/大图/2.png') },
			{ index: 3, url: require('@/assets/img/详情/复古系列/复古唇釉/小图/3.png'), bigUrl: require('@/assets/img/详情/复古系列/复古唇釉/大图/3.png') },
			{ index: 3, url: require('@/assets/img/详情/复古系列/复古唇釉/小图/1.png'), bigUrl: require('@/assets/img/详情/复古系列/复古唇釉/大图/1.png') },
		],
		color: [
			{
				active: '#cb7267',
				name: '#13 随意杏茶',
				span1: '玫瑰蜜桃乌龙茶 素颜可涂不挑皮',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古唇釉/色号大图/1.jpg')
			},
			{
				active: '#bd4f4c',
				name: '#14 杏梨乌龙',
				span1: '裸棕茶色透闪 又仙又欲',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古唇釉/色号大图/2.jpg')
			},
			{
				active: '#962e2a',
				name: '#15 沉睡玛瑙',
				span1: '可可红茶 气质显白',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古唇釉/色号大图/3.jpg')
			},
			{
				active: '#97403b',
				name: '#16 干野浓栗',
				span1: '栗子咖啡红棕 复古焦糖',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古唇釉/色号大图/4.jpg')
			},
			{
				active: '#9d452c',
				name: '#17 秋山琴弦',
				span1: '冷秋灰调黄棕闪 独一份的高级',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古唇釉/色号大图/5.jpg')
			},
		]
	},
	{
		id: 3,
		defalut: require('@/assets/img/fugu/yy1.png'),
		active: require('@/assets/img/fugu/yy2.png'),
		series: '复古系列',
		seriesName: '复古四色眼影',
		intro: '高闪服帖珠光 一抹好晕染',
		zhName: '酵色「复古四色眼影」',
		enName: 'Vintage Rewind Eyeshadow',
		price: '99',
		of: '4.3g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/复古系列/复古眼盘/小图/4.jpg'), bigUrl: require('@/assets/img/详情/复古系列/复古眼盘/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/复古系列/复古眼盘/小图/2.jpg'), bigUrl: require('@/assets/img/详情/复古系列/复古眼盘/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/复古系列/复古眼盘/小图/3.jpg'), bigUrl: require('@/assets/img/详情/复古系列/复古眼盘/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/复古系列/复古眼盘/小图/1.jpg'), bigUrl: require('@/assets/img/详情/复古系列/复古眼盘/大图/1.jpg') },
		],
		color: [
			{
				active: '#a7323d',
				name: '#F07 醉金盘',
				span1: '火花流金盘 炙热弗拉门戈',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古眼盘/色号大图/1.jpg')
			},
			{
				active: '#925e9c',
				name: '#F08 浮金盘',
				span1: '紫醉迷金盘 浮生若梦',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古眼盘/色号大图/2.jpg')
			},
			{
				active: '#b6968b',
				name: '#F09 雾金盘',
				span1: '烟棕雾金盘 90s的回眸',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古眼盘/色号大图/3.jpg')
			}
		]
	},
	{
		id: 4,
		defalut: require('@/assets/img/fugu/cxb1.png'),
		active: require('@/assets/img/fugu/cxb2.png'),
		series: '复古系列',
		seriesName: '复古四色眼影',
		intro: '眼唇两用 一笔成型',
		zhName: '酵色「眼唇双用笔」',
		enName: 'Vintage Rewind Lip&Eye Gel Liner',
		price: '59',
		of: '0.38g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/复古系列/复古眼唇笔/小图/4.jpg'), bigUrl: require('@/assets/img/详情/复古系列/复古眼唇笔/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/复古系列/复古眼唇笔/小图/2.jpg'), bigUrl: require('@/assets/img/详情/复古系列/复古眼唇笔/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/复古系列/复古眼唇笔/小图/3.jpg'), bigUrl: require('@/assets/img/详情/复古系列/复古眼唇笔/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/复古系列/复古眼唇笔/小图/1.jpg'), bigUrl: require('@/assets/img/详情/复古系列/复古眼唇笔/大图/1.jpg') },
		],
		color: [
			{
				active: '#813129',
				name: '#P01 脂红',
				span1: '热情暗红 打造大气妆容',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古眼唇笔/色号大图/1.jpg')
			},
			{
				active: '#944d49',
				name: '#P02 梅紫',
				span1: '浓情酒红 用在眼线点缀日常',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古眼唇笔/色号大图/2.jpg')
			},
			{
				active: '#9e533e',
				name: '#P03 杏棕',
				span1: '蜜色黄棕 日常好用',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古眼唇笔/色号大图/3.jpg')
			},
			{
				active: '#c19287',
				name: '#P04 烟咖',
				span1: '灰烟裸咖 中性的浅奶咖色',
				span2: ' ',
				color: require('@/assets/img/详情/复古系列/复古眼唇笔/色号大图/4.jpg')
			}
		]
	},
]

