<template>
  <div class="details-box">
    <div class="left">
      <div class="swiper-container-box">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item,index) in smimgUrl"
              :key="index"
            >
              <img
                ref="clickImg"
                :src="item.url"
                :bigsrc="item.bigUrl"
              >
            </div>
          </div>
        </div>
        <div
          class="swiper-button-prev"
          @click="upBig"
        ><img src="@/assets/img/up.png"></div>
        <div
          class="swiper-button-next"
          @click="downBig"
        ><img src="@/assets/img/down.png"></div>
      </div>
    </div>
    <div
      class="middle"
      ref="middle"
    >
      <img :src="bigimgUrl">
    </div>
    <div class="right">
      <div class="right-box">
        <!-- 面包屑 -->
        <div class="breadcrumb">
          <span @click="breadcrumbPath('Home')">主页 | </span>
          <span @click="breadcrumbPath('about')">所有产品 | </span>
          <span
            @click="breadcrumbPath(PaTh)"
            v-if="seriesShow"
          >{{intro.series}} | </span>
          <span>{{intro.seriesName}}</span>
        </div>
        <!-- 商品名称 -->
        <div class="product-name-zh">
          <h2>{{intro.zhName}}</h2>
        </div>
        <div class="product-name-en">
          <h2
            id="h2Width"
            ref="h2Width"
          >{{intro.enName}}</h2>
          <!-- 商品价格 -->
          <div class="product-price">
            <h2>￥{{intro.price}}<span>/{{intro.of}}</span></h2>
          </div>
          <hr />
          <!-- 商品简介 -->
          <div class="product-intro">
            <span v-if="$route.query.name === '游染系列'">{{intro.intro2}}</span>
            <span v-else>{{intro.intro}}</span><br>
            <span></span>
          </div>
          <div
            class="product-color"
            ref="colorWidth"
            :style="{width:colorWidth+'px'}"
          >
            <div
              v-for="(item,idx) in defaultItem.color"
              class="black-box"
            >
              <div
                :class="idx==index?'black':''"
                @click="handleColor(item,idx)"
              >
                <div
                  class="colours"
                  :style="{background:item.active}"
                >
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <!-- 商品颜色简介 -->
        <div class="product-color-details">
          <h4>{{detailsItem.name}}</h4>
          <span>{{detailsItem.span1}}</span><br>
          <span>{{detailsItem.span2}}</span>
        </div>

        <!-- 下拉菜单 -->
        <div class="drop-select">
          <div
            class="select"
            @click.stop="isShow = !isShow"
          >
            <p
              class="list-color"
              :style="{background:detailsItem.active}"
            ></p>
            {{detailsItem.name}}
            <div class="icon">
              <img
                v-show="isShow"
                src="../../assets/img/up.png"
                alt=""
              >
              <img
                v-show="!isShow"
                src="../../assets/img/down.png"
                alt=""
              >
            </div>
          </div>
          <div
            v-show="isShow"
            class="drop-select-list"
          >
            <div v-for="(item,index) in defaultItem.color">
              <div
                @click="handleColor(item,index)"
                class="list"
              >
                <p
                  class="list-color"
                  :style="{background:item.active}"
                ></p>{{item.name}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper JS
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/swiper-bundle.css';
// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade, Mousewheel } from 'swiper/core';
// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade, Mousewheel]);
import { sichouDetail } from '../series/sichou/detail'
import { hupoDetail } from '../series/hupo/detail'
import { Y2KDetail } from '../series/y2k/detail'
import { beikeDetail1, beikeDetail2 } from '../series/beike/detail'
import { fuboDetail } from '../series/fubo/detail'
import { xiachanDetail } from '../series/xiachan/detail'
import { youranDetail } from '../series/youran/detail'
import { fuguDetail } from '../series/vintage/detail'
import { aboutDetail } from '../about/detail'
import { chunbuDetail } from '../lips/chunbu/detail'
import { glossDetail } from '../lips/gloss/detail'
import { stickDetail } from '../lips/stick/detail'
import { yanbuDetail } from '../eyes/yanbu/detail'
import { shadowDetail } from '../eyes/shadow/detail'
import { linerDetail } from '../eyes/liner/detail'
import { jiacaiDetail } from '../cheek/jiacai/detail'
import { highlightDetail } from '../cheek/highlight/detail'
import { blusherDetail } from '../cheek/blusher/detail'
import { dizhuangDetail } from '../fandation/dizhuang/detail'
import { concealerDetail } from '../fandation/concealer/detail'
import { puffcakeDetail } from '../fandation/puffcake/detail'

export default {
  name: 'detail',
  data() {
    return {
      bigimgUrl: '', // 左侧大图
      index: 0,
      defaultItem: [], // 获取当前页面的所有素材文案
      smimgUrl: [], // 左侧小图
      detailsItem: {}, // 
      isShow: false,
      swiper: null,
      intro: '',
      seriesShow: true,
      PaTh: '',
      colorWidth: null,
      widthNum: 0,

    }
  },
  created() {
    // 如需要加密参数 
    // let params = JSON.parse(this.$Base64.decode(this.$route.query.info))
    if (this.$route.query.name === '丝绸系列') {
      this.defaultItem = sichouDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'sichou'
    } else if (this.$route.query.name === '琥珀系列') {
      this.defaultItem = hupoDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'hupo'
    } else if (this.$route.query.name === 'Y2K系列') {
      this.defaultItem = Y2KDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'y2k'
    } else if (this.$route.query.name === '贝壳系列1') {
      this.defaultItem = beikeDetail1.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'beike'
    } else if (this.$route.query.name === '贝壳系列2') {
      this.defaultItem = beikeDetail2.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'beike'
    } else if (this.$route.query.name === '浮波系列') {
      this.defaultItem = fuboDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'fubo'
    } else if (this.$route.query.name === '唇部') {
      this.defaultItem = chunbuDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'chunbu'
    } else if (this.$route.query.name === '眼部') {
      this.defaultItem = yanbuDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'yanbu'
    } else if (this.$route.query.name === '颊彩') {
      this.defaultItem = jiacaiDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'jiacai'
    } else if (this.$route.query.name === '底妆') {
      this.defaultItem = dizhuangDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'dizhuang'
    } else if (this.$route.query.name === '所有产品') {
      this.defaultItem = aboutDetail.find((item) => { return item.id == this.$route.query.id })
      this.seriesShow = false
      // 如需要加密参数
      // } else if (params.name === '所有产品') {
      // this.defaultItem = aboutDetail.find((item) => { return item.id == params.id })
    } else if (this.$route.query.name === '唇釉') {
      this.defaultItem = glossDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'gloss'
    } else if (this.$route.query.name === '口红') {
      this.defaultItem = stickDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'stick'
    } else if (this.$route.query.name === '眼影') {
      this.defaultItem = shadowDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'shadow'
    } else if (this.$route.query.name === '眼线') {
      this.defaultItem = linerDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'liner'
    } else if (this.$route.query.name === '腮红') {
      this.defaultItem = blusherDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'blusher'
    } else if (this.$route.query.name === '高光') {
      this.defaultItem = highlightDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'blusher'
    } else if (this.$route.query.name === '遮瑕') {
      this.defaultItem = concealerDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'concealer'
    } else if (this.$route.query.name === '粉饼') {
      this.defaultItem = puffcakeDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'puffcake'
    } else if (this.$route.query.name === '夏蝉系列') {
      this.defaultItem = xiachanDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'xiachan'
    } else if (this.$route.query.name === '游染系列') {
      this.defaultItem = youranDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'youran'
    } else if (this.$route.query.name === '复古系列') {
      this.defaultItem = fuguDetail.find((item) => { return item.id == this.$route.query.id })
      this.PaTh = 'vintage'
    }
    if (this.seriesShow) {
      localStorage.setItem('toId', '')
    }
    this.smimgUrl = this.defaultItem.imgUrl // 获取所有大图和小图
    this.intro = this.defaultItem
    this.detailsItem = this.defaultItem.color[0]
    this.bigimgUrl = this.smimgUrl[0].bigUrl

  },
  mounted() {
    this.colorWidth = this.$refs.h2Width.clientWidth
    // 点击目标区域之外可关闭(隐藏)菜单
    document.addEventListener('click', (e) => {
      if (e.target.className != 'drop-select-list') {
        this.isShow = false;
      }
    })
    this.initSwiper()
  },
  methods: {
    upBig() {
      let swIndex = this.swiper.realIndex
      if (swIndex === 0) {
        swIndex = 3
      } else if (swIndex === 1) {
        swIndex = 0
      } else if (swIndex === 2) {
        swIndex = 1
      } else if (swIndex === 3) {
        swIndex = 2
      }
      this.bigimgUrl = this.smimgUrl[swIndex].bigUrl
    },
    downBig() {
      let swIndex = this.swiper.realIndex
      if (swIndex === 0) {
        swIndex = 1
      } else if (swIndex === 1) {
        swIndex = 2
      } else if (swIndex === 2) {
        swIndex = 3
      } else if (swIndex === 3) {
        swIndex = 0
      }
      this.bigimgUrl = this.smimgUrl[swIndex].bigUrl
    },
    initSwiper() {
      // swiper相关设置
      const _this = this
      this.swiper = new Swiper('.swiper-container', {
        direction: 'vertical',
        loop: true,
        lazy: true,
        slideToClickedSlide: true,
        slidesPerView: 4,
        slidesOffsetBefore: 163,
        roundLengths: true,
        centeredSlidesBounds: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        touchRatio: 0, // 禁止鼠标滑动,
        watchSlidesVisibility: true, //防止不可点击
        mousewheel: true,
        forceToAxis: true,
        on: {
          click: function (a, event) {
            const path = event.path || (event.composedPath && event.composedPath());
            if (path[0].currentSrc) {
              _this.bigimgUrl = event.target.getAttribute('bigsrc')
            }
          },
        },
      });
    },
    handleColor(item, idx) {
      this.index = idx;// 控制颜色黑边框
      this.detailsItem = item
      this.isShow = false
      this.swiper.destroy()
      this.$nextTick(() => {
        this.initSwiper()
        this.bigimgUrl = this.defaultItem.color[idx].color
      })
    },
    breadcrumbPath(paths) {
      if (paths === 'about') {
        const Id = localStorage.getItem('toId')
        this.$router.push({ name: paths, params: { Id: Id } })
      } else {
        this.$router.push({ name: paths, params: { Id: '' } })
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.details-box {
  // width: 78.125rem;
  width: 80vw;
  margin: 0 auto;
  // overflow: hidden;
  display: flex;
  height: calc(100vh - 7vh);
  position: relative;
  .left {
    // display: inline-block;
    width: 115px;
    margin-right: 36px;
    display: flex;
    position: relative;
    margin-left: 12.5rem;
    .swiper-container-box {
      align-items: flex-end;
      right: 0;
      margin-right: 36px;
      position: absolute;
      bottom: 0;
      height: calc(163px * 4);
      // margin-top: 200%;
      width: 115px;
      .swiper-container {
        // border: 1px solid #000;
        position: absolute;
        bottom: 0;
        bottom: 20px;
        width: 115px;
        height: calc(163px * 4);
        // overflow: hidden;
        img {
          width: 100%;
        }
        .swiper-slide {
          opacity: 0.3;
        }
        .swiper-slide-active {
          opacity: 1;
        }
      }
    }
    .swiper-button-prev:after {
      display: none;
    }
    .swiper-button-next:after {
      display: none;
    }
    .swiper-button-prev {
      position: absolute;
      // top: 5.563rem;
      top: -60px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .swiper-container-box .swiper-button-next {
      position: absolute;
      bottom: 0;
      top: 94%;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .middle {
    margin-right: 36px;
    display: flex;
    align-items: center;
    height: 90vh;
    img {
      height: 90vh;
    }
  }
  .right {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    height: calc(100vh - 7vh);
    .right-box {
      .breadcrumb {
        margin-bottom: 0.938rem;
        span {
          font-size: 14px;
          color: #9195a3;
          cursor: pointer;
        }
      }
      .product-name-zh {
        h2 {
          position: relative;
          font-size: 24px;
          right: -15px;
          text-indent: -10px;
          font-weight: normal;
        }
      }
      .product-name-en {
        // border: hotpink 1px solid;
        // padding-bottom: 50px;
        overflow: hidden;
        h2 {
          font-size: 24px;
          font-weight: normal;
          display: inline-block;
        }
        .product-price {
          // padding-bottom: 0.938rem;
          // border-bottom: 1px solid #9195a3;
          margin-bottom: 0.938rem;
          h2 {
            font-size: 28px;
            font-weight: 300;
            span {
              font-size: 16px;
              color: rgb(193, 193, 193);
              font-weight: 100;
            }
          }
        }
        .product-intro {
          margin-top: 15px;
          margin-bottom: 15px;
          span {
            font-size: 14px;
          }
        }
        .product-color :after {
          content: "";
          display: block;
          clear: both;
        }
        .product-color {
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
          margin-bottom: 15px;
          float: right;
          .black-box {
            display: flex;
            justify-content: flex-end;
            margin: 2px 2px;
            box-sizing: border-box;
          }
          .black {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            // width: 32px;
            // height: 32px;
            // padding: 1px;

            border: 1px solid #000;
            border-radius: 50%;
          }
          .colours {
            box-sizing: border-box;
            // width: 30px;
            // height: 30px;
            padding: 14px;
            border-radius: 50%;
            border: 1px solid #fff;
          }
        }
      }
      .product-color-details {
        margin-top: 0.938rem;

        margin-bottom: 10px;
        h4 {
          font-size: 21.28px;
        }
        span {
          font-size: 14px;
        }
      }
      // 商品颜色下拉
      .drop-select {
        display: flex;
        justify-content: flex-end;
        position: relative;
      }
      .select {
        width: 220px;
        height: 35px;
        border: 1px solid #000;
        // text-align: left;
        display: flex;
        font-size: 16px;

        // justify-content: left;
        align-items: center;
        .list-color {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid #fff;
          box-sizing: border-box;
          display: inline-block;
          margin: 0 15px 0 10px;
        }
        .icon {
          vertical-align: middle;
          flex: 1;
          text-align: right;
          margin-right: 10px;
          img {
            vertical-align: middle;
          }
        }
      }
      .drop-select-list {
        position: absolute;
        top: 35px;
        width: 220px;
        // border: 1px solid #000;
        border-top: none;
        box-sizing: border-box;
        height: 105px;
        overflow: auto;
        // overflow: scroll;
        box-shadow: 0px 0px 3px #888888;
        div {
          height: 35px;
          line-height: 35px;
          display: flex;
          justify-content: left;
          align-items: center;
          font-size: 16px;
          .list-color {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid #fff;
            box-sizing: border-box;
            display: inline-block;
            margin: 0 15px 0 10px;
          }
        }
        div:hover {
          background: wheat;
        }
      }
    }
  }

  // .right {
  //   display: inline-block;
  //   position: absolute;
  //   right: 0;
  //   height: calc(100vh - 7vh);
  //   // width: 282px;
  //   // background: aqua;
  //   overflow: hidden;
  //   text-align: right;
  //   .right-box {
  //     overflow: hidden;

  //     position: relative;
  //     top: 50%;
  //     transform: translateY(-50%);
  //     // 面包屑
  //     .breadcrumb {
  //       margin-bottom: 0.938rem;
  //       span {
  //         font-size: 14px;
  //         color: #9195a3;
  //         cursor: pointer;
  //       }
  //     }
  //     .product-name-zh {
  //       h2 {
  //         font-size: 24px;
  //         // text-indent: 10px;
  //         font-weight: normal;
  //       }
  //     }
  //     .product-name-en {
  //       margin-bottom: 0.938rem;
  //       h2 {
  //         font-size: 24px;

  //         font-weight: normal;
  //       }
  //     }

  //     //商品价格
  //     .product-price {
  //       padding-bottom: 0.938rem;
  //       border-bottom: 1px solid #9195a3;
  //       margin-bottom: 0.938rem;
  //       h2 {
  //         font-size: 28px;
  //         font-weight: 300;
  //         span {
  //           font-size: 16px;
  //           color: rgb(193, 193, 193);
  //           font-weight: 100;
  //         }
  //       }
  //     }

  //     //商品简介
  //     .product-intro {
  //       margin-bottom: 15px;
  //       span {
  //         font-size: 14px;
  //       }
  //     }

  //     // 商品颜色选择
  //     .product-color {
  //       // border: #000 1px solid;
  //       width: 380px;
  //       display: flex;
  //       flex-wrap: wrap;
  //       position: relative;
  //       right: 0;
  //       justify-content: flex-end;
  //       padding-bottom: 15px;
  //       border-bottom: 1px solid #9195a3;
  //       margin-bottom: 20px;
  //       box-sizing: border-box;

  //       .black-box {
  //         margin: 2px 2px;
  //         box-sizing: border-box;
  //       }
  //       .black {
  //         box-sizing: border-box;

  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //         width: 32px;
  //         height: 32px;
  //         border: 1px solid #000;
  //         border-radius: 50%;
  //       }
  //       .colours {
  //         box-sizing: border-box;

  //         width: 30px;
  //         height: 30px;
  //         border-radius: 50%;
  //         border: 1px solid #fff;
  //       }
  //     }
  //     // 商品颜色详情
  //     .product-color-details {
  //       margin-bottom: 10px;
  //       h4 {
  //         font-size: 21.28px;
  //       }
  //       span {
  //         font-size: 14px;
  //       }
  //     }

  //     // 商品颜色下拉
  //     .drop-select {
  //       display: flex;
  //       justify-content: flex-end;
  //       position: relative;
  //     }
  //     .select {
  //       width: 220px;
  //       height: 35px;
  //       border: 1px solid #000;
  //       // text-align: left;
  //       display: flex;
  //       font-size: 16px;

  //       // justify-content: left;
  //       align-items: center;
  //       .list-color {
  //         width: 20px;
  //         height: 20px;
  //         border-radius: 50%;
  //         border: 1px solid #fff;
  //         box-sizing: border-box;
  //         display: inline-block;
  //         margin: 0 15px 0 10px;
  //       }
  //       .icon {
  //         vertical-align: middle;
  //         flex: 1;
  //         text-align: right;
  //         margin-right: 10px;
  //         img {
  //           vertical-align: middle;
  //         }
  //       }
  //     }
  //     .drop-select-list {
  //       position: absolute;
  //       top: 35px;
  //       width: 220px;
  //       // border: 1px solid #000;
  //       border-top: none;
  //       box-sizing: border-box;
  //       height: 105px;
  //       overflow: auto;
  //       // overflow: scroll;
  //       box-shadow: 0px 0px 3px #888888;
  //       div {
  //         height: 35px;
  //         line-height: 35px;
  //         display: flex;
  //         justify-content: left;
  //         align-items: center;
  //         font-size: 16px;
  //         .list-color {
  //           width: 20px;
  //           height: 20px;
  //           border-radius: 50%;
  //           border: 1px solid #fff;
  //           box-sizing: border-box;
  //           display: inline-block;
  //           margin: 0 15px 0 10px;
  //         }
  //       }
  //       div:hover {
  //         background: wheat;
  //       }
  //     }
  //   }
  // }
}
</style>