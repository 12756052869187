export const youranDetail = [
    {
        id: 1,
        defalut: require('@/assets/img/youran/cn1.png'),
        active: require('@/assets/img/youran/cn2.png'),
        series: '游染系列',
        seriesName: '游染唇泥',
        intro: '丝绒质地 浓郁哑光',
        intro2: '全新浓郁哑光唇泥',
        zhName: '酵色「游染唇泥」',
        enName: 'Motion Color Matte Lipgloss',
        price: '79',
        of: '3.2g',
        imgUrl: [
            { index: 1, url: require('@/assets/img/详情/游染系列/游染唇泥/小图/4.jpg'), bigUrl: require('@/assets/img/详情/游染系列/游染唇泥/大图/4.jpg') },
            { index: 2, url: require('@/assets/img/详情/游染系列/游染唇泥/小图/2.jpg'), bigUrl: require('@/assets/img/详情/游染系列/游染唇泥/大图/2.jpg') },
            { index: 3, url: require('@/assets/img/详情/游染系列/游染唇泥/小图/3.jpg'), bigUrl: require('@/assets/img/详情/游染系列/游染唇泥/大图/3.jpg') },
            { index: 3, url: require('@/assets/img/详情/游染系列/游染唇泥/小图/1.jpg'), bigUrl: require('@/assets/img/详情/游染系列/游染唇泥/大图/1.jpg') },
        ],
        color: [
            {
                active: '#9a5043',
                name: '#V901 漂浮月球',
                span1: '杏咖肉桂奶茶 微妙纯欲感',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染唇泥/色号大图/1.jpg')
            },
            {
                active: '#70342f',
                name: '#V902 暗藏酥巧',
                span1: '灰棕红茶生巧色',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染唇泥/色号大图/2.jpg')
            },
            {
                active: '#722b25',
                name: '#V903 半山松石',
                span1: '灰棕脏橘 时髦感口棕',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染唇泥/色号大图/3.jpg')
            },

            {
                active: '#7a2c2c',
                name: '#V904 不眠暗红',
                span1: '高级显白 大气又性感',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染唇泥/色号大图/4.jpg')

            },
            {
                active: '#9b4b48',
                name: '#V905 风花半粉',
                span1: '杏奶烟熏玫瑰色 温柔不挑皮',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染唇泥/色号大图/5.jpg')
            },
            {
                active: '#8d414b',
                name: '#V906 烟蔷紫藤',
                span1: '烟熏蔷薇雾紫 灰调不荧光',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染唇泥/色号大图/6.jpg')
            },
            {
                active: '#914f59',
                name: '#V907 冰透乌梅',
                span1: '梅子调烟灰雾霭色 丧甜妆必备',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染唇泥/色号大图/7.jpg')
            },
            {
                active: '#bc554e',
                name: '#V908 幻想豆蔻',
                span1: '米杏奶油粉 纯欲奶桃',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染唇泥/色号大图/8.jpg')
            },
            {
                active: '#9b3d3e',
                name: '#V909 零落藤雾',
                span1: '蜜桃盐奶粉 富家淑女感',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染唇泥/色号大图/9.jpg')
            },
            {
                active: '#761817',
                name: '#V910 半杯微醺',
                span1: '烟熏复古红梨 复古味十足',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染唇泥/色号大图/10.jpg')
            },
        ]
    },
    {
        id: 2,
        defalut: require('@/assets/img/youran/yy1.png'),
        active: require('@/assets/img/youran/yy2.png'),
        series: '游染系列',
        seriesName: '游染眼盘',
        intro: '高闪服帖珠光 一抹好晕染',
        intro2: '高闪服帖珠光 一抹好晕染',
        zhName: '酵色「游染四色眼影」',
        enName: 'The Neverending Summer Blush',
        price: '69',
        of: '4.8g',
        imgUrl: [
            { index: 1, url: require('@/assets/img/详情/游染系列/游染眼盘/小图/4.jpg'), bigUrl: require('@/assets/img/详情/游染系列/游染眼盘/大图/4.jpg') },
            { index: 2, url: require('@/assets/img/详情/游染系列/游染眼盘/小图/2.jpg'), bigUrl: require('@/assets/img/详情/游染系列/游染眼盘/大图/2.jpg') },
            { index: 3, url: require('@/assets/img/详情/游染系列/游染眼盘/小图/3.jpg'), bigUrl: require('@/assets/img/详情/游染系列/游染眼盘/大图/3.jpg') },
            { index: 3, url: require('@/assets/img/详情/游染系列/游染眼盘/小图/1.jpg'), bigUrl: require('@/assets/img/详情/游染系列/游染眼盘/大图/1.jpg') },
        ],
        color: [
            {
                active: '#986762',
                name: '#F01 赤陶盘',
                span1: '染上赤陶的风霜',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染眼盘/色号大图/1.jpg')
            },
            {
                active: '#d7a29b',
                name: '#F02 烟粉盘',
                span1: '染上弥漫的烟粉',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染眼盘/色号大图/2.jpg')
            },
            {
                active: '#c6755d',
                name: '#F03 橘海盘',
                span1: '染上橘海的波浪',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染眼盘/色号大图/3.jpg')
            },
            {
                active: '#b18675',
                name: '#F04 沙砾盘',
                span1: '染上沙砾的故事',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染眼盘/色号大图/4.jpg')
            },
            {
                active: '#d8bcc8',
                name: '#F05 海盐盘',
                span1: '染上海盐的清爽',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染眼盘/色号大图/5.jpg')
            },
            {
                active: '#b14856',
                name: '#F06 野莓盘',
                span1: '染上野莓的酸甜',
                span2: ' ',
                color: require('@/assets/img/详情/游染系列/游染眼盘/色号大图/6.jpg')
            },
        ]
    }
]

