export const puffcakeDetail = [
	{
		id: 1,
		defalut: require('@/assets/img/底妆素材/产品模特图/BKFB1.jpg'),
		active: require('@/assets/img/底妆素材/产品模特图/BKFB2.jpg'),
		series: '底妆',
		seriesName: '贝壳粉饼',
		zhName: '酵色「贝壳粉饼」',
		enName: 'Spiral Shell Amplifier Powder',
		intro: 'Q弹帖肤不卡粉 清透柔焦伪素颜',
		price: '129',
		of: '10g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/1.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/4.jpg') },
		],
		color: [
			{
				active: '#f1e8d9',
				name: '#01 自然色',
				span1: '为亚洲肤色定制',
				span2: '奶油淡黄色粉体 遮瑕修肤',
				color: require('@/assets/img/详情/贝壳素材/贝壳粉饼/001.jpg')
			}
		]
	},
]