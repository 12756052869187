export const jiacaiDetail = [
	{
		id: 1,
		defalut: require('@/assets/img/颊彩素材/产品模特图/SCGG1.jpg'),
		active: require('@/assets/img/颊彩素材/产品模特图/SCGG2.jpg'),
		series: '颊彩',
		seriesName: '丝绸高光',
		zhName: '酵色「丝绸高光」',
		enName: 'Silky Silk Highlighter',
		price: '69',
		of: '3.6-4.5g',
		intro: '自然水光感 为亚洲暖黄和冷白皮定制',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/丝绸素材/丝绸高光/4.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/04.jpg') },
			{ index: 2, url: require('@/assets/img/详情/丝绸素材/丝绸高光/2.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/02.jpg') },
			{ index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸高光/3.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/03.jpg') },
			{ index: 3, url: require('@/assets/img/详情/丝绸素材/丝绸高光/1.jpg'), bigUrl: require('@/assets/img/详情/丝绸素材/丝绸高光/01.jpg') },
		],
		color: [
			{
				active: '#e5e0da',
				name: '#06 月落银湖',
				span1: '细密珍珠白',
				span2: '如湖面洒落的粼粼银光',
				color: require('@/assets/img/详情/丝绸素材/丝绸高光/06.jpg')
			},
			{
				active: '#d7c3c3',
				name: '#07 香槟粉梦',
				span1: '月光香槟金',
				span2: '斟满少女的午后美梦',
				color: require('@/assets/img/详情/丝绸素材/丝绸高光/07.jpg')
			}
		]
	},
	{
		id: 2,
		defalut: require('@/assets/img/颊彩素材/产品模特图/BKGG1.jpg'),
		active: require('@/assets/img/颊彩素材/产品模特图/BKGG2.jpg'),
		series: '颊彩',
		seriesName: '贝壳高光',
		intro: '轻盈细腻 高级光泽',
		zhName: '酵色「贝壳高光」',
		enName: 'Spiral Shell Highlighter',
		price: '69',
		of: '3.6 - 4.5g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d1.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳高光/x4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳高光/d4.jpg') },
		],
		color: [
			{
				active: '#dddddd',
				name: '#01 月光碎石',
				span1: '银色大闪',
				span2: '聚焦闪耀白月光',
				color: require('@/assets/img/详情/贝壳素材/贝壳高光/01.jpg')
			},
			{
				active: '#cbb2cd',
				name: '#02 耀眼薄雾',
				span1: '粉紫大闪偏光',
				span2: '甜系妆容必备',
				color: require('@/assets/img/详情/贝壳素材/贝壳高光/02.jpg')
			},
			{
				active: '#deb599',
				name: '#03 日光之吻',
				span1: '自然香槟金细闪',
				span2: '湿漉漉的水光感',
				color: require('@/assets/img/详情/贝壳素材/贝壳高光/03.jpg')
			},
			{
				active: '#f3eed5',
				name: '#05 寻迹流星',
				span1: '蓝粉香槟烤粉',
				span2: '清透妆效',
				color: require('@/assets/img/详情/贝壳素材/贝壳高光/05.jpg')
			}
		]
	},
	{
		id: 3,
		defalut: require('@/assets/img/颊彩素材/产品模特图/BKSH1.jpg'),
		active: require('@/assets/img/颊彩素材/产品模特图/BKSH2.jpg'),
		series: '颊彩',
		seriesName: '贝壳腮红',
		intro: '复古摩登 透明感纯欲妆',
		zhName: '酵色「贝壳腮红」',
		enName: 'Spiral Shell Blush',
		price: '79',
		of: '3.5g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/腮红/小图/4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/腮红/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/腮红/小图/2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/腮红/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/腮红/小图/3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/腮红/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/腮红/小图/1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/腮红/大图/1.jpg') },
		],
		color: [
			{
				active: '#e3c3ba',
				name: '#N03 迷路晨雾',
				span1: '自然裸粉 少女气色',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/腮红/大图/n03.jpg')
			},
			{
				active: '#c28f8b',
				name: '#N04 糖渍早樱',
				span1: '灰调肉粉 清透细闪',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/腮红/大图/N04.jpg')
			},
			{
				active: '#af7178',
				name: '#N05 碎金玫瑰',
				span1: '烟熏玫瑰 朦胧偏光',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/腮红/大图/n05.jpg')
			},
			{
				active: '#a16b5f',
				name: '#B03 肉桂赤岩',
				span1: '高级暖棕 细腻金闪',
				span2: ' ',
				color: require('@/assets/img/详情/贝壳素材/腮红/大图/b03.jpg')
			}
		]
	},
	{
		id: 4,
		defalut: require('@/assets/img/颊彩素材/产品模特图/HPSH1.jpg'),
		active: require('@/assets/img/颊彩素材/产品模特图/HPSH2.jpg'),
		series: '颊彩',
		seriesName: '琥珀腮红',
		intro: '微醺色泽 日杂氛围感',
		zhName: '酵色「琥珀腮红」',
		enName: 'Tortoise Shell Blush',
		price: '79',
		of: '3.5g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/琥珀素材/腮红/小图/4.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/腮红/大图/4.jpg') },
			{ index: 2, url: require('@/assets/img/详情/琥珀素材/腮红/小图/2.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/腮红/大图/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/腮红/小图/3.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/腮红/大图/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/琥珀素材/腮红/小图/1.jpg'), bigUrl: require('@/assets/img/详情/琥珀素材/腮红/大图/1.jpg') },
		],
		color: [
			{
				active: '#e3bfb9',
				name: '#N01 玫瑰烤奶',
				span1: '肉粉玫瑰色 温柔气质',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/腮红/大图/n01.jpg')
			},
			{
				active: '#bc7c6a',
				name: '#N02 山茶拿铁',
				span1: '棕调土咖色 日系加持',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/腮红/大图/n02.jpg')
			},
			{
				active: '#c28a74',
				name: '#B01 焦糖银杏',
				span1: '焦糖奶橘色 慵懒氛围',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/腮红/大图/b01.jpg')
			},
			{
				active: '#c58073',
				name: '#B02 树莓朗姆',
				span1: '微醺豆沙色 黄皮挚爱',
				span2: ' ',
				color: require('@/assets/img/详情/琥珀素材/腮红/大图/b02.jpg')
			},
		]
	},
]